import React from 'react'
import Slider from "react-slick";

const ClientSection = () => {
    var sliderSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 6,
        autoplay: true,
        speed: 8000,
        autoplaySpeed: 1,
        cssEase: "linear",
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 6,
                slidesToScroll: 2,
                infinite: true,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                speed: 5000,
                autoplaySpeed: 1,
              }
            }
          ]
      };
	return (
		<React.Fragment>
            <section id="clients" class="clients section-bg">
                <div class="container">
                    <div class="section-title">
                        <h2>Our partners</h2>
                    </div>
                    
                    <div class="row" data-aos="zoom-in">
                        <Slider {...sliderSettings}>
                            <div>
                                <a href="https://beyonnex.io/en" target="_blank">
                                    <img src="assets/img/clients/beyonnex.svg" class="img-fluid" alt=""/>                            
                                </a>
                            </div>
                            <div>
                                <a href="https://sprintingsoftware.com/" target="_blank">
                                    <img src="assets/img/clients/sprinting.png" class="img-fluid" alt=""/>                            
                                </a>
                            </div>
                            <div>
                                <a href="https://www.nttdata.com/global/en/" target="_blank">
                                    <img src="assets/img/clients/nttdata.png" class="img-fluid" alt=""/>                            
                                </a>
                            </div>
                            <div>
                                <a href="https://gourban.co/" target="_blank">
                                    <img src="assets/img/clients/goUrban.svg" class="img-fluid" alt=""/>                            
                                </a>
                            </div>
                            <div>
                                <a href="https://novacode.rs/" target="_blank">
                                    <img src="assets/img/clients/novaCode.png" class="img-fluid" alt=""/>                            
                                </a>
                            </div>
                            <div>
                                <a href="https://infinitydev.rs/" target="_blank">
                                    <img src="assets/img/clients/infinitydev.png" class="img-fluid" alt=""/>                            
                                </a>
                            </div>
                            <div>
                                <a href="https://talentembassy.se/" target="_blank">
                                    <img src="assets/img/clients/talentembassy.png" class="img-fluid" alt=""/>                            
                                </a>
                            </div>
                            <div>
                                <a href="https://www.emulate.energy/" target="_blank">
                                    <img src="assets/img/clients/emulate.webp" class="img-fluid" alt=""/>                            
                                </a>
                            </div>
                            <div>
                                <a href="https://ftninformatika.com" target="_blank">
                                    <img src="assets/img/clients/ftninformatika.png" class="img-fluid" alt=""/>                            
                                </a>
                            </div>
                            <div>
                                <a href="https://www.pangea.ai/" target="_blank">
                                    <img src="assets/img/clients/pangeatext.png" class="img-fluid" alt=""/>                            
                                </a>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
	    </React.Fragment>
	);
};

export default ClientSection;