import React from 'react'
import AboutUs from '../components/AboutUs';
import ClientSection from '../components/ClientSection';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Header from '../components/Header';
import MainBody from '../components/MainBody';
import Services from '../components/Services';
import Team from '../components/Team';
import TechnologiesStack from '../components/TechnologiesStack';

const HomePage = () => {
	return (
		<React.Fragment>
            <MainBody/>
            <AboutUs/>
            <ClientSection/>
            <Services/>
            <TechnologiesStack/>
            <Team/>
            <Contact/>
            <Footer/>
	    </React.Fragment>
	);
};

export default HomePage;