import React from 'react'

const Footer = () => {
	return (
		<React.Fragment>
            <div id="footer">

                <div class="footer-top">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 footer-contact">
                                <h3>CyberLab</h3>
                                <p>
                                    Ljubice Ravasi 47<br></br>
                                    Novi Sad 21000<br></br>
                                    Serbia<br></br>
                                    <strong>Phone:</strong> +381 64 49 50 873<br></br>
                                    <strong>Email:</strong> office@cyberlab.rs
                                </p>
                            </div>   
                            <div class="col-lg-3 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#about">About us</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#services">Services</a></li>
                                </ul>
                            </div>     
                            <div class="col-lg-3 col-md-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#services">DevOps</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#services">Agile Development</a></li>
                                    <li><i class="bx bx-chevron-right"></i> <a href="#services">Web Development</a></li>
                                </ul>
                            </div>
                            <div class="col-lg-3 col-md-6 footer-links">
                                <h4>Our Social Networks</h4>
                                <div class="social-links mt-3">
                                    <a href="https://www.linkedin.com/company/cyberlabolutions/" target="_blank" class="linkedin"><i class="bx bxl-linkedin"></i></a>
                                    <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                                    <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
                                    <a href="https://www.pangea.ai/vendors/cyberlab-solutions/">
                                        <span>
                                            <img className="pangea-logo-style" src="assets/img/pangealogo.png"></img>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>    
                    </div>   
                </div> 
                <div class="container footer-bottom clearfix">
                    <div class="copyright">
                        &copy; Copyright <strong><span>CyberLab</span></strong>. All Rights Reserved
                    </div>
                    <div class="credits">
                        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                    </div>
                </div>  
            </div>
            <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
	    </React.Fragment>
	);
};

export default Footer;

