import React from 'react'

const MainBody = () => {
	return (
		<React.Fragment>
            <section id="hero" class="d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                            <h1>We have the best solution for your business</h1>
                            <h2>We are a team of talented young engineers</h2>
                            <div class="d-flex justify-content-center justify-content-lg-center">
                                <a href="#contact" class="btn-get-started scrollto">Contact Us</a>
                            </div>

                        </div>
                        <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                            <img src="assets/img/headerimage.png" class="img-fluid animated" alt=""/>
                        </div>
                    </div>
                </div>
            </section>
	    </React.Fragment>
	);
};

export default MainBody;