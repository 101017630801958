import React from 'react'

const AboutUs = () => {
	return (
		<React.Fragment>
            <section id="about" class="about">
                <div class="container" data-aos="fade-up">
                    <div class="section-title">
                    <h2>About Us</h2>
                    </div>
                    <div class="row content">
                    <div class="col-lg-6">
                        <p>
                            CyberLab is an IT company founded in 2020. <br>
                            </br>
                            We primarily do the following services: 
                        </p>
                        <ul>
                        <li><i class="ri-check-double-line"></i> DevOps Engineering</li>
                        <li><i class="ri-check-double-line"></i> Agile Development</li>
                        <li><i class="ri-check-double-line"></i> Web development</li>
                        </ul>
                    </div>
                    <div class="col-lg-6 pt-4 pt-lg-0">
                        <p>
                        Our company is a group of talented young people, ready to learn and improve to provide the best service to clients. We currently have 10 employees with a tendency for constant growth. We try to work in the latest technologies and follow software development trends and we really care about the final result.<br></br>
                        </p>
                        <a href="#about" class="btn-learn-more">Learn More</a>
                    </div>
                    </div>
                </div>
            </section>
	    </React.Fragment>
	);
};

export default AboutUs;