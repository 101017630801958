import React from 'react'

const Services = () => {
	return (
		<React.Fragment>
           <section id="services" class="services section-bg">
                <div class="container" data-aos="fade-up">
                    <div class="section-title">
                        <h2>Services</h2>
                    </div>
                    <div class="row">
                        <div class="col-xl-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                            <div class="icon-box">
                                <div class="icon"><i class="bx bx-cloud"></i></div>
                                <h4><a href="#services">DevOps</a></h4>
                                <p>For the past decade, our dedicated DevOps teams have accumulated impressive expertise in uniting development and operations using various stack of technologies: AWS, Azure, Kubernetes, Terraform, Docker, Jenkins, etc. DevOps services we provide allow businesses to enjoy the stability of operating environments, cut the development time, and get faster feature delivery.</p>
                            </div>
                        </div>

                        <div class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                            <div class="icon-box">
                            <div class="icon"><i class="bx bx-loader-circle"></i></div>
                            <h4><a href="#services">Agile Development</a></h4>
                            <p>Business is going through rapid digital transformation. 
                                Stay ahead of the game. Work with us to develop 
                                your next digital solution quickly and reliably.
                            </p>
                            </div>
                        </div>

                        <div class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                            <div class="icon-box">
                            <div class="icon"><i class="bx bx-code-block"></i></div>
                            <h4><a href="#services">Web Development</a></h4>
                            <p>Build your web presence in today’s competitive business environment and stay one step ahead of your competition.We provide full web development services, from creating a prototype to building a complex web app.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
	    </React.Fragment>
	);
};

export default Services;

