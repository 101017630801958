import React, {useState} from 'react'
import {init, sendForm} from "emailjs-com"

init("user_uxNop5WwjccLpdwhIzDTD");
const Contact = () => {
    const  mapStyle = {"border":"0","width":"100%","height":"290px"}
    const [number, setNumber] = useState("");
    const [email, setEmail] =  useState("");
    const [name, setName] =  useState("");
    const [message, setMessage] =  useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const handleSubmit = (evt) => {
        evt.preventDefault();
        sendForm('service_y4ubimd', 'template_k03oadn', '#contactForm')
    .then(function(response) {
        //console.log('SUCCESS!', response.status, response.text);
        setShowSuccessMessage(true)
        reset();
    }, function(error) {
        setShowErrorMessage(true)
        //console.log('FAILED...', error);
    });}

    const reset = ()=> {
        setName("")
        setNumber("")
        setEmail("")
        setMessage("")
    }

	return (
		<React.Fragment>
            <section id="contact" class="contact">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Contact</h2>
          <p>If you have a question or a business offer, feel free to contact us. <br></br>Our team will do its best to respond as soon as possible </p>
        </div>

        <div class="row">

          <div class="col-lg-5 d-flex align-items-stretch">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>Ljubice Ravasi 47, Novi Sad</p>
              </div>

              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>office@cyberlab.rs</p>
              </div>

              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>Phone number:</h4>
                <p>+381 64 49 50 873</p>
              </div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2809.64048642601!2d19.792908335153914!3d45.23483968989374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x861f08277db54480!2zNDXCsDE0JzA1LjQiTiAxOcKwNDcnNTAuNiJF!5e0!3m2!1sen!2srs!4v1653044038433!5m2!1sen!2srs" style={mapStyle} frameborder="0" allowfullscreen></iframe>

            </div>

          </div>

          <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
            <form action="forms/contact.php" id="contactForm" name="contactForm" role="form" class="php-email-form" onSubmit={handleSubmit}>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="name">Your Name*</label>
                  <input type="text" name="name" value={name} onChange={e=>setName(e.target.value)} class="form-control" id="name" required/>
                </div>
                <div class="form-group col-md-6">
                  <label for="name">Your Email*</label>
                  <input type="email" class="form-control" value={email} onChange={e=>setEmail(e.target.value)} name="email" id="email" required/>
                </div>
              </div>
              <div class="form-group">
                <label for="name">Phone Number (optional)</label>
                <input type="text" class="form-control" type="number" value={number} onChange={e=>setNumber(e.target.value)} name="number" id="number"/>
              </div>
              <div class="form-group">
                <label for="name">Message</label>
                <textarea class="form-control" value={message} onChange={e=>setMessage(e.target.value)} name="message" rows="10" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div hidden={!showErrorMessage} class="error-message">We have some problem with email servis. Please try later</div>
                <div hidden={!showSuccessMessage} class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
    </section>

            
	    </React.Fragment>
	);
};

export default Contact;