import React from 'react'

const TechnologiesStack = () => {
	return (
		<React.Fragment>
            <section id="features" class="features">
                <div class="container">

                    <div class="section-title" data-aos="fade-up">
                    <h2>Technologies stack</h2>
                    </div>

                    <div class="row" data-aos="fade-up" data-aos-delay="300">
                        
                        <div class="col-lg-2 col-md-6 col-6 d-flex">
                            <div class="icon-box">
                                <img src="assets/img/technologies/aws.svg" alt=""/>
                                <h5 class="ml-1">AWS</h5>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex">
                            <div class="icon-box">
                                <img src="assets/img/technologies/microsoftazure.svg" alt=""/>
                                <h5 class="ml-1">Azure</h5>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex">
                            <div class="icon-box">
                                <img src="assets/img/technologies/googlecloud.svg" alt=""/>
                                <h5 class="ml-1">Google cloud</h5>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex">
                            <div class="icon-box">
                                <img src="assets/img/technologies/digitalocean.svg" alt=""/>
                                <h5 class="ml-1">Digital ocean</h5>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex">
                            <div class="icon-box">
                                <img src="assets/img/technologies/jenkins.png" alt=""/>
                                <h5 class="ml-1">Jenkins</h5>                        
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/gitlab.svg" alt=""/>
                                <h5 class="ml-1">GitLab</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex">
                            <div class="icon-box">
                                <img src="assets/img/technologies/githubactions.png" alt=""/>
                                <h5 class="ml-1">GitHub Actions</h5>                        
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/prometheus.png" alt=""/>
                                <h5 class="ml-1">Prometheus</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2" >
                            <div class="icon-box">
                                <img src="assets/img/technologies/grafana.svg" alt=""/>
                                <h5 class="ml-1">Grafana</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/elkstack.svg" alt=""/>
                                <h5 class="ml-1">ELK Stack</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/terraformio.svg" alt=""/>
                                <h5 class="ml-1">Terraform</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/kubernetes.svg" alt=""/>
                                <h5 class="ml-1">Kubernetes</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/ansible.svg" alt=""/>
                                <h5 class="ml-1">Ansible</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/kafka.png" alt=""/>
                                <h5 class="ml-1">Kafka</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/linux.svg" alt=""/>
                                <h5 class="ml-1">Linux</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/docker.svg" alt=""/>
                                <h5 class="ml-1">Docker</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/python.svg" alt=""/>
                                <h5 class="ml-1">Python</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/nginx.svg" alt=""/>
                                <h5 class="ml-1">Nginx</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/apache.svg" alt=""/>
                                <h5 class="ml-1">Apache</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/puppet.svg" alt=""/>
                                <h5 class="ml-1">Puppet</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/mysql.svg" alt=""/>
                                <h5 class="ml-1">MySQL</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/postgres.svg" alt=""/>
                                <h5 class="ml-1">PostgreSQL</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/rabbitmq.svg" alt=""/>
                                <h5 class="ml-1">RabbitMQ</h5>       
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-6 d-flex mt-2">
                            <div class="icon-box">
                                <img src="assets/img/technologies/redis.svg" alt=""/>
                                <h5 class="ml-1">Redis</h5>       
                            </div>
                        </div>
                    </div>
                </div>
            </section>
	    </React.Fragment>
	);
};

export default TechnologiesStack;