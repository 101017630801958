import logo from './logo.svg';
import './App.css';
import HomePage from './Pages/HomePage';
import Header from './components/Header';
import React from 'react';

class App extends React.Component {
   componentDidMount() {
     this.props.hideLoader();
     this.props.showHeader();
   }
   
   render() {   
     return (
        <div>
           <HomePage></HomePage>
        </div>
     ); 
   }
 }

export default App;
