import React from 'react'

const Team = () => {
	return (
		<React.Fragment>
            <section id="team" class="team section-bg">
                <div class="container" data-aos="fade-up">
                    <div class="section-title">
                        <h2>Team</h2>
                    </div>

                    <div class="row">

                    <div class="col-lg-6">
                        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
                        <div class="pic"><img src="assets/img/team/milangluhic.jpg" class="img-fluid" alt=""/></div>
                        <div class="member-info">
                            <h4>Milan Gluhić</h4>
                            <span>CEO and DevOps Engineer</span>
                            <p>Experienced DevOps Engineer with a demonstrated
                                history of working in the computer software industry.
                                Infrastructure as a Code, AWS, Azure, Kubernetes.</p>
                            <div class="social">
                                <a href="https://www.linkedin.com/in/milan-gluhic/" target="_blank" > <i class="ri-linkedin-box-fill"></i> </a>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="200">
                        <div class="pic"><img src="assets/img/team/draganperic.jpg" class="img-fluid" alt=""/></div>
                        <div class="member-info">
                            <h4>Dragan Perić</h4>
                            <span>DevOps Engineer</span>
                            <p>DevOps Engineer with 3+ years of experience in the computer software industry. Infrastructure as a Code using Terraform, AWS, CI/CD pipelines. Interested in cloud computing and automation.</p>
                            <div class="social">
                                <a href="https://www.linkedin.com/in/dragan-peri%C4%87-3654641b4/" target="_blank"> <i class="ri-linkedin-box-fill"></i> </a>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mt-4">
                        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="300">
                        <div class="pic"><img src="assets/img/team/andrijavojnovic.jpg" class="img-fluid" alt=""/></div>
                        <div class="member-info">
                            <h4>Andrija Vojnović</h4>
                            <span>DevOps Engineer</span>
                            <p>DevOps Engineer who has a history in software development and is interested in cloud computing and new technologies and tools such as Kubernetes and Terraform.</p>
                            <div class="social">
                                <a href="https://www.linkedin.com/in/andrija-vojnovic-172390177/" target="_blank" > <i class="ri-linkedin-box-fill"></i> </a>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div class="col-lg-6 mt-4">
                        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
                        <div class="pic"><img src="assets/img/team/jelenkomirkovic.jpg" class="img-fluid" alt=""/></div>
                        <div class="member-info">
                            <h4>Jelenko Mirković</h4>
                            <span>DevOps Engineer</span>
                            <p>DevOps Engineer studying at the Faculty of Technical Sciences. Interested in cloud computing technologies such as AWS, Azure, Google Cloud.</p>
                            <div class="social">
                                <a href="https://www.linkedin.com/in/jelenko-mirkovic-817a64226/" target="_blank" > <i class="ri-linkedin-box-fill"></i> </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-6 mt-4">
                        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
                        <div class="pic"><img src="assets/img/team/nikolakolovic.jpg" class="img-fluid" alt=""/></div>
                        <div class="member-info">
                            <h4>Nikola Kolović</h4>
                            <span>DevOps Engineer</span>
                            <p>DevOps Engineer who has a history in software development. Interested in cloud computing and Infrastructure as a Code technologies.</p>
                            <div class="social">
                                <a href="https://www.linkedin.com/in/nikola-kolovic/" target="_blank" > <i class="ri-linkedin-box-fill"></i> </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
                        <div class="pic"><img src="assets/img/team/danijelradakovic.jpg" class="img-fluid" alt=""/></div>
                        <div class="member-info">
                            <h4>Danijel Radaković</h4>
                            <span>DevOps Engineer</span>
                            <p>Experienced DevOps Engineer with a demonstrated history of working in the computer software industry. Interested in cloud computing and blockchain technologies.</p>
                            <div class="social">
                                <a href="https://www.linkedin.com/in/danijel-radakovic-9ba319213/" target="_blank" > <i class="ri-linkedin-box-fill"></i> </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
                        <div class="pic"><img src="assets/img/team/dijanadacin.jpg" class="img-fluid" alt=""/></div>
                        <div class="member-info">
                            <h4>Dijana Dacin
                            </h4>
                            <span>HR Manager</span>
                            <p>Experienced HR Manager with a demonstrated history of working in media and computer software industries. Interested in recruiting and staffing employees, organizational structure and management.</p>
                            <div class="social">
                                <a href="https://www.linkedin.com/in/dijana-dacin/" target="_blank" > <i class="ri-linkedin-box-fill"></i> </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
                        <div class="pic"><img src="assets/img/team/lnk.JPG" class="img-fluid" alt=""/></div>
                        <div class="member-info">
                            <h4>Nikola Medić
                            </h4>
                            <span>Frontend Developer</span>
                            <p>Experienced Frontend Developer with a demonstrated history of working in the computer software industry. Interested in researching and following trends on Awwwards, Google, and Facebook services.
                            </p>
                            <div class="social">
                                <a href="https://www.linkedin.com/in/nikolamedic/" target="_blank" > <i class="ri-linkedin-box-fill"></i> </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
                        <div class="pic"><img src="assets/img/team/andjela2.jpg" class="img-fluid" alt=""/></div>
                        <div class="member-info">
                            <h4>Anđela Đurić
                            </h4>
                            <span>Frontend Developer</span>
                            <p>Frontend developer with a demonstrated history of working in the computer software industry. Interested in technologies such as JavaScript and ReactJS
                            </p>
                            <div class="social">
                                <a href="https://www.linkedin.com/in/an%C4%91ela-%C4%91uri%C4%87-7157a1240/" target="_blank" > <i class="ri-linkedin-box-fill"></i> </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <div class="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
                        <div class="pic"><img src="assets/img/team/dragomir-team.jpg" class="img-fluid" alt=""/></div>
                        <div class="member-info">
                            <h4>Dragomir Veselinović
                            </h4>
                            <span>DevOps Engineer</span>
                            <p>DevOps engineer with a background in telecommunication and system engineering. Proactive, self-driven with excellent communication skills. Interested in cloud computing and automation.
                            </p>
                            <div class="social">
                                <a href="https://www.linkedin.com/in/dragomir-veselinovi%C4%87-9a1011a8/" target="_blank" > <i class="ri-linkedin-box-fill"></i> </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                </div>
            </section>
	    </React.Fragment>
	);
};

export default Team;